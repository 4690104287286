<template>
  <div class="wrapper">
    <div style="height: 60px; line-height: 60px; font-size: 20px; padding-left: 50px; color: white;
      background-color: rgba(0,0,0,0.2)">书籍漂流
    </div>
    <div
        style="margin: 150px auto; background-color: #fff; width: 350px; height: 300px; padding: 20px; border-radius: 10px">
      <div style="margin: 20px 0; text-align: center; font-size: 24px"><b>登 录</b></div>
      <el-form :model="user" :rules="rules" ref="userForm">
        <el-form-item prop="username">
          <el-input size="medium" prefix-icon="el-icon-user" v-model="user.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="medium" prefix-icon="el-icon-lock" show-password v-model="user.password"></el-input>
        </el-form-item>
        <el-form-item style="margin: 10px 0; text-align: right">
          <el-button type="success" size="small" style="float: left" autocomplete="off"
                     @click="$router.push('/front/home')">游客进入
          </el-button>
          <el-button type="warning" size="small" autocomplete="off" @click="$router.push('/register')">前往注册</el-button>
          <el-button type="primary" size="small" autocomplete="off" @click="login">登录</el-button>

        </el-form-item>
        <el-form-item style="margin: 10px 0; text-align: right">
          <el-button type="text" size="mid" autocomplete="off" @click="handlePass">找回密码</el-button>
        </el-form-item>
      </el-form>
    </div>


    <el-dialog title="找回密码" :visible.sync="dialogFormVisible" width="30%">
      <el-form label-width="0px" size="small">
        <el-form-item prop="email">
          <el-input placeholder="请输入邮箱" size="medium" prefix-icon="el-icon-user" v-model="pass.email"></el-input>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <el-input placeholder="请输入验证码" size="medium" prefix-icon="el-icon-chat-dot-round" v-model="pass.verificationCode">
            <template slot="append">
              <el-button size="medium" type="link" style="color: blue" @click="sendVerificationCode" :disabled="countingDown">{{ countdownText }}</el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="passwordBack">重置密码</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {resetRouter, setRoutes} from "@/router";

export default {
  name: "Login",
  data() {
    return {
      user: {},
      pass: {},
      countdown: 0,
      countingDown: false,
      dialogFormVisible: false,
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    // 重置路由
    resetRouter()
    localStorage.removeItem("user");
    localStorage.removeItem("menus");
  },
  computed: {
    countdownText() {
      return this.countdown > 0 ? `${this.countdown}秒后重发` : '发送验证码';
    }
  },
  methods: {
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          console.log(process.env.VUE_APP_BASE_URL)
          this.request.post("/user/login", this.user).then(res => {
            if (res.code === '200') {
              localStorage.setItem("user", JSON.stringify(res.data))  // 存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus))  // 存储用户信息到浏览器

              // 动态设置当前用户的路由
              setRoutes()
              this.$router.push("/front/home")
              this.$message.success("登录成功")
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    handlePass() {
      this.dialogFormVisible = true
      this.pass = {}
    },

    sendVerificationCode() {
      if (!this.pass.email) {
        this.$message.error('请先输入邮箱');
        return;
      }
      // Simulate sending verification code, here you would implement actual sending logic
      this.request.post("/user/verificationCode", this.pass).then(res => {
        if (res.code === '200') {
          this.$message.success("验证码已发送")
        } else {
          this.$message.error(res.msg)
        }
      })
      this.countdown = 60;
      this.countingDown = true;
      const countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(countdownInterval);
          this.countingDown = false;
        }
      }, 1000);
    },
    passwordBack() {
      this.request.put("/user/reset", this.pass).then(res => {
        if (res.code === '200') {
          this.$message.success("重置密码成功，新密码为：123，请尽快修改密码")
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style>
.wrapper {
  height: 100vh;
  background-image: linear-gradient(to bottom right, #4169E1, #87CEFA);
  overflow: hidden;
}
</style>
